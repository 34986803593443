
import { defineComponent } from 'vue';
import Separation from '@/components/StudyCreation/Separation.vue'
import Input from '~/components/Input/Input.vue'
import CountrySelector from '@/components/StudyCreation/CountrySelector.vue'
import LanguageSelector from '@/components/StudyCreation/LanguageSelector.vue'
import SelectionBox from '@/components/StudyCreation/SelectionBox.vue'
import SwitchInput from '~/components/SwitchInput/SwitchInput.vue'
import DeviceSelector from '@/components/StudyCreation/DeviceSelector.vue'
import Collapsable from '~/components/Collapsable/Collapsable.vue'
import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
import InfoMessage from '@/components/StudyCreation/InfoMessage.vue'

export default defineComponent({
	name: 'PublicStudyStep2',
	components: {
		Separation,
		Input,
		CountrySelector,
		LanguageSelector,
		SelectionBox,
		SwitchInput,
		DeviceSelector,
		Collapsable,
		SuiteInput,
		InfoMessage
	},
	props: {
		config: {
			type: Object,
			default: () => { }
		},
		isWatUx: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			collapseWelcome: false
		}
	},
	methods: {
		checkDevices() {
			const deviceSelector: any = this.$refs.deviceSelector
			this.config.desktopPercent = deviceSelector.desktopPercent;
			this.config.mobilePercent = deviceSelector.mobilePercent;
			this.config.platform = deviceSelector.type;
		},
		formattedText(text: any) {
			return text.replace(/<br\s*\/?>/gi, "\n");
		},
		setDevicesData() {
			const deviceSelector: any = this.$refs.deviceSelector
			if (this.config.desktopPercent >= 0 && this.config.mobilePercent >= 0) {
				deviceSelector.desktopPercent = this.config.desktopPercent
				deviceSelector.mobilePercent = this.config.mobilePercent
			}
			if (deviceSelector) {
				deviceSelector.setDeviceType(this.config.platform)
			}
		}
	},
	async mounted() {
		if (this.config.welcomePage.text == '') {
			this.config.welcomePage.text = this.formattedText(this.$t('suite_watux_step2_welcome_text_default'));
		}
	}
})
